import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular-ivy';
// Removed TextMaskModule import as we'll implement our own mask directive
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { environment } from './../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardsBarComponent } from './components/cards-bar/cards-bar.component';
import { ConfirmStepComponent } from './components/confirm-step/confirm-step.component';
import { FinishStepComponent } from './components/finish-step/finish-step.component';
import { PaymentStepComponent } from './components/payment-step/payment-step.component';
import { InputMaskDirective } from './directives/input-mask.directive';
import { TextMaskDirective } from './directives/text-mask.directive';
import { AddApiKeyInterceptor } from './interceptors/add-api-key.interceptor';
import { MaterialModule } from './material.module';
import { PaymentModule } from './pages/payment/payment.module';
import { PaymentCheckoutComponent } from './pages/payment-checkout/payment-checkout.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SafePipe } from './pipes/safe.pipe';
import { PaymentGrerjComponent } from './pages/payment-grerj/payment-grerj.component';
import { ApiModule, BASE_PATH } from 'api-client';
import { PixStepComponent } from './components/pix-step/pix-step.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PaymentComponent,
    PaymentCheckoutComponent,
    PaymentGrerjComponent,
    InputMaskDirective,
    TextMaskDirective,
    SafePipe,
    CardsBarComponent,
    ConfirmStepComponent,
    FinishStepComponent,
    PaymentStepComponent,
    PixStepComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    ApiModule,
    BrowserAnimationsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PaymentModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pt',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    provideNgxMask(),
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' }
    },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddApiKeyInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: [InputMaskDirective, TextMaskDirective]
})
export class AppModule {}
