import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  CardIssuerFees,
  CountriesService,
  DreampayPayment,
  DreampayPaymentResponse,
  Establishments,
  PaymentLinks,
  PaymentsService,
  Settings,
  SettingsService,
  SettingsUSDService
} from 'api-client';
import { getValidationConfigFromCardNo } from 'src/app/helpers/card.helper';
import { Translations } from 'src/app/models/translations.model';
import { LogService } from 'src/app/services/log.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { StoreService } from 'src/app/services/store.service';
import { GenericValidator } from 'src/app/validators/cpfValidator';
import { luhnValidator } from 'src/app/validators/luhnValidator';
import { environment } from 'src/environments/environment';
import { Transaction } from 'src/app/models/transaction.class';

@Component({
  selector: 'payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss']
})
export class PaymentStepComponent implements OnInit, OnChanges {
  private serviceSubscription: Subscription = new Subscription();

  // Form related properties
  ccForm: FormGroup;
  bloco1;
  bloco2;
  bloco3;
  bloco4;
  bloco5;
  blocks;

  // UI properties
  bankName = 'DreamPay';
  linkCurrency: string;
  currentEnvironment: string = environment.env;
  timer: Subscription;

  private _translations: Translations = new Translations();

  // Store property accessors
  get paymentLink(): PaymentLinks {
    return this._storeService.getPaymentLink();
  }

  get establishment(): Establishments {
    return this._storeService.getEstablishment();
  }

  get cardImgPath(): string {
    return this._storeService.currentState.cardImgPath;
  }

  get isCredit(): boolean {
    return this._storeService.currentState.isCredit;
  }

  get transaction(): Transaction {
    return this._storeService.currentState.transaction || new Transaction();
  }

  get cardIssuerFees(): CardIssuerFees {
    return this._storeService.currentState.cardIssuerFees;
  }

  get dreampayPayment(): DreampayPayment {
    return this._storeService.currentState.dreampayPayment;
  }

  get paymentResponse(): DreampayPaymentResponse {
    return this._storeService.currentState.paymentResponse;
  }

  get settings(): Settings {
    return this._storeService.currentState.settings;
  }

  get finishStepMessage(): string {
    return this._storeService.getFinishStepMessage();
  }

  get creditOnly(): boolean {
    return this._storeService.currentState.creditOnly;
  }

  get minimumInstallments(): number {
    return this._storeService.currentState.minimumInstallments;
  }

  get defaultInstallments(): number {
    return this._storeService.currentState.defaultInstallments;
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _paymentsService: PaymentsService,
    private _countriesService: CountriesService,
    private _settingsService: SettingsService,
    private _settingsUSDService: SettingsUSDService,
    private _sharedFunctionsService: SharedFunctionsService,
    private _logger: LogService,
    private _storeService: StoreService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.ccForm) {
      this.ccForm.patchValue({
        name: '',
        cardNumber: '',
        cpf: '',
        expirationDate: '',
        securityCode: ''
      });
    }
  }

  ngOnInit(): void {
    this.linkCurrency = this.paymentLink?.currencyCode.toUpperCase();
    this._initCCForm();
    this._initSettings();
    // Initialize store transaction if not already set
    if (!this._storeService.currentState.transaction) {
      this._storeService.updateState({ transaction: new Transaction() });
    }

    // Set initial form validators based on payment method
    this.updateFormValidators(this.isCredit);
  }

  private _initSettings() {
    this._countriesService
      .apiCountriesIdGet(this.establishment.countryID)
      .pipe(take(1))
      .subscribe(country => {
        const currencySymbol = country.currencySymbol;

        if (!this.paymentLink.paid && !this.paymentLink.canceled && !this.paymentLink.expired) {
          this._sharedFunctionsService.sendAlertOpened(this.paymentLink, this.establishment, currencySymbol);
        }

        this._sharedFunctionsService.initTranslations(country.uiLanguage.trim()).subscribe(response => {
          this._translations = response;
        });

        this.ccForm.controls.parcelas.setValue(1);

        const ano = new Date().getFullYear();
        const mes = new Date().getUTCMonth() + 1;
        const dia = new Date().getUTCDate();
        const intDate = ano * 10000 + mes * 100 + dia;

        if (this.linkCurrency === 'EUR') {
          this._settingsService
            .apiSettingsDateGet(intDate)
            .pipe(take(1))
            .subscribe(
              settings => {
                this._storeService.updateState({ settings: settings });

                this._storeService.updateTransaction({
                  exchangeRate:
                    Math.round(
                      this.settings?.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread) * 10000 + 0.5
                    ) / 10000
                });

                this.calculateAmountToPay();
                this._logger.context('exchangeRate', {
                  exchangeRate: this.transaction.exchangeRate
                });
              },
              error => {
                this._logger.toastError('Erro a obter as definições.', error);
              }
            );
        } else if (this.linkCurrency === 'USD') {
          this._settingsUSDService
            .apiSettingsUSDDateGet(intDate)
            .pipe(take(1))
            .subscribe(
              settings => {
                this._storeService.updateState({ settings: settings });

                this._storeService.updateTransaction({
                  exchangeRate:
                    Math.round(
                      this.settings?.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread) * 10000 + 0.5
                    ) / 10000
                });

                this.calculateAmountToPay();
                this._logger.context('exchangeRate', {
                  exchangeRate: this.transaction.exchangeRate
                });
              },
              error => {
                this._logger.toastError('Erro a obter as definições.', error);
              }
            );
        } else if (this.linkCurrency === 'BRL') {
          this._settingsService
            .apiSettingsDateGet(intDate)
            .pipe(take(1))
            .subscribe(
              settings => {
                settings.oficialExchangeRate = 1;
                settings.iof = 0;
                settings.spread = 0;
                this._storeService.updateState({ settings: settings });
                this._storeService.updateTransaction({ exchangeRate: 1 });
                this._storeService.updateState({ creditOnly: true });
                this._storeService.updateState({ minimumInstallments: 2 });

                this.ccForm.controls.parcelas.setValue(this.defaultInstallments);

                this.calculateAmountToPay();

                this._logger.context('exchangeRate', {
                  exchangeRate: this.transaction.exchangeRate
                });
              },
              error => {
                this._logger.toastError('Erro a obter as definições.', error);
              }
            );
        } else {
          this._storeService.updateState({ settings: null });
        }
      });
  }

  private _initCCForm() {
    this.ccForm = this._formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(27)]),
      cardNumber: new FormControl(''),
      cpf: new FormControl('', [Validators.required, GenericValidator.isValidCpf()]),
      expirationDate: new FormControl(''),
      securityCode: new FormControl(''),
      credit: new FormControl('1', [Validators.required]),
      parcelas: new FormControl(this.minimumInstallments),
      totalToPay: new FormControl(0)
    });

    // Update validators based on payment method
    this.updateFormValidators(this.isCredit);

    this.serviceSubscription.add(
      this.ccForm.controls.cardNumber.valueChanges.subscribe(
        val => {
          this.blocks = this.ccForm.controls.cardNumber.value.split(' ');
          if (Array.isArray(this.blocks)) {
            this.bloco1 = this.blocks[0];
            this.bloco2 = this.blocks[1];
            this.bloco3 = this.blocks[2];
            this.bloco4 = this.blocks[3];
            this.bloco5 = this.blocks[4];
          } else {
            this.bloco1 = '';
            this.bloco2 = '';
            this.bloco3 = '';
            this.bloco4 = '';
            this.bloco5 = '';
          }
          this.bankName = 'DreamPay';
        },
        error => {
          this._logger.error('Erro nos dados do cartão.', error);
        }
      )
    );
  }

  getCardNumberControl(): AbstractControl | null {
    return this.ccForm?.get('cardNumber');
  }

  onCardNumberBlur() {
    this.calculateAmountToPay();
  }

  getExpirationDateControl(): AbstractControl | null {
    return this.ccForm?.get('expirationDate');
  }

  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }

  onOptionsSelected(value: string) {
    this.calculateAmountToPay();
  }

  onCreditSelected(value: string) {
    this.calculateAmountToPay();
  }

  onRadioChange($event) {
    const isCredit = $event.value === '1';

    if (isCredit) {
      this._storeService.updateState({ isCredit: true });
      this.ccForm.controls.parcelas.setValue(this.defaultInstallments);
    } else {
      this._storeService.updateState({ isCredit: false });
    }

    // Update form validators based on payment method
    this.updateFormValidators(isCredit);
    this.calculateAmountToPay();
  }

  /**
   * Updates form validators based on the selected payment method
   * @param isCredit Whether credit card is selected (true) or PIX (false)
   */
  updateFormValidators(isCredit: boolean) {
    const cardNumberControl = this.ccForm.get('cardNumber');
    const expirationDateControl = this.ccForm.get('expirationDate');
    const securityCodeControl = this.ccForm.get('securityCode');

    if (isCredit) {
      // Credit card selected - add validators
      cardNumberControl?.setValidators([Validators.required, Validators.minLength(11), luhnValidator()]);
      expirationDateControl?.setValidators([Validators.required, Validators.maxLength(7), expDateValidators]);
      securityCodeControl?.setValidators([
        Validators.required,
        Validators.maxLength(4),
        Validators.minLength(1),
        Validators.max(9999)
      ]);
    } else {
      // PIX selected - remove validators
      cardNumberControl?.clearValidators();
      expirationDateControl?.clearValidators();
      securityCodeControl?.clearValidators();
    }

    // Update validation status
    cardNumberControl?.updateValueAndValidity();
    expirationDateControl?.updateValueAndValidity();
    securityCodeControl?.updateValueAndValidity();
  }

  calculateAmountToPay() {
    if (this.settings) {
      let totalValueBRL: number;
      let totalValueEUR: number;
      let installmentValueBRL: number;
      let efectiveExchangeRate: number =
        this.settings?.oficialExchangeRate * (1 + this.settings.iof + this.settings.spread);
      let exchangeCostBRL: number;
      let cardProcessingCostBRL: number;
      let maxAntecipationCostBRL: number;
      let cardProcessmentFee: number;

      if (this.isCredit) {
        switch (this.ccForm.controls.parcelas.value) {
          case 1:
            cardProcessmentFee = this.settings.cardProcessmentFee;
            break;
          case 2:
            cardProcessmentFee = this.settings.twoMonthsFee;
            break;
          case 3:
            cardProcessmentFee = this.settings.threeMonthsFee;
            break;
          case 4:
            cardProcessmentFee = this.settings.fourMonthsFee;
            break;
          case 5:
            cardProcessmentFee = this.settings.fiveMonthsFee;
            break;
          case 6:
            cardProcessmentFee = this.settings.sixMonthsFee;
            break;
          case 7:
            cardProcessmentFee = this.settings.sevenMonthsFee;
            break;
          case 8:
            cardProcessmentFee = this.settings.eightMonthsFee;
            break;
          case 9:
            cardProcessmentFee = this.settings.nineMonthsFee;
            break;
          case 10:
            cardProcessmentFee = this.settings.tenMonthsFee;
            break;
          case 11:
            cardProcessmentFee = this.settings.elevenMonthsFee;
            break;
          case 12:
            cardProcessmentFee = this.settings.twelveMonthsFee;
            break;

          default:
            cardProcessmentFee = this.settings.twelveMonthsFee;
            break;
        }
      } else {
        cardProcessmentFee = this.settings.debitCardProcessmentFee;
      }

      this.transaction.exchangeRate = efectiveExchangeRate;

      if (this.isCredit) {
        if (this.paymentLink.establishmentSupportsFinancingCosts) {
          totalValueEUR = this.paymentLink.valueEUR / (1 - this.settings.operacionalMargin - cardProcessmentFee);
        } else {
          totalValueEUR =
            this.paymentLink.valueEUR /
            (1 - this.settings.operacionalMargin - this.settings.antecipationFee - cardProcessmentFee) /
            (1 - (this.ccForm.controls.parcelas.value - 1) * this.settings.financingFee);
        }
        totalValueBRL = totalValueEUR * this.transaction.exchangeRate;
        installmentValueBRL = totalValueBRL / this.ccForm.controls.parcelas.value;
        this.ccForm.controls.totalToPay.setValue(installmentValueBRL.toFixed(2));

        exchangeCostBRL = this.paymentLink.valueEUR * this.transaction.exchangeRate;
        cardProcessingCostBRL = totalValueBRL * cardProcessmentFee;
        maxAntecipationCostBRL =
          totalValueBRL /
          (1 - this.settings.antecipationFee) /
          (1 - (this.ccForm.controls.parcelas.value - 1) * this.settings.financingFee);
      } else {
        this.ccForm.controls.parcelas.setValue(1);
        totalValueEUR = this.paymentLink.valueEUR / (1 - cardProcessmentFee - this.settings.operacionalMargin);
        totalValueBRL = totalValueEUR * this.transaction.exchangeRate;
        installmentValueBRL = totalValueBRL;

        this.ccForm.controls.totalToPay.setValue(installmentValueBRL.toFixed(2));
        exchangeCostBRL = this.paymentLink.valueEUR * this.transaction.exchangeRate;
        cardProcessingCostBRL = totalValueBRL * cardProcessmentFee;
        maxAntecipationCostBRL = 0;
      }

      this._storeService.updateTransaction({
        cardProcessingComission: cardProcessmentFee * 100,
        estimatedExchangeCostBRL: Math.round(exchangeCostBRL * 100 + 0.5) / 100,
        cardProcessingCostBRL: Math.round(cardProcessingCostBRL * 100 + 0.5) / 100,
        maxAntecipationCostBRL: Math.round(maxAntecipationCostBRL * 100 + 0.5) / 100,
        totalValueBRL: Math.round(totalValueBRL * 100 + 0.5) / 100,
        totalValueEUR: Math.round(totalValueEUR * 100 + 0.5) / 100,
        installmentValueBRL: Math.round(installmentValueBRL * 100 + 0.5) / 100
      });
    }
  }

  submeter() {
    // Save form values to store
    this._storeService.setCardFormData({
      cardNumber: this.ccForm.controls.cardNumber.value,
      cardHolder: this.ccForm.controls.name.value,
      cpf: this.ccForm.controls.cpf.value,
      expirationDate: this.ccForm.controls.expirationDate.value,
      securityCode: this.ccForm.controls.securityCode.value
    });

    // Save installments to store
    this._storeService.setInstallments(this.ccForm.controls.parcelas.value);

    if (this.isCredit) {
      this._storeService.setFinishStepMessage('Por favor aguarde enquanto processamos o pagamento...');

      this._logger.context('form', this.ccForm.value);
      this._logger.context('isCredit', {
        isCredit: this.isCredit,
        valueEUR: this.paymentLink.valueEUR
      });
      this._logger.sendToSentry('Payment button clicked.');

      const payment = {
        posID: 'payment-link',
        establishmentID: this.establishment.id,
        softDescriptor: this.establishment.softDescriptor,
        establismentName: this.establishment.establismentName,
        cardHolder: this.ccForm.controls.name.value,
        cardNumber: this.ccForm.controls.cardNumber.value.replaceAll(' ', ''),
        cardExpirationDate:
          this.ccForm.controls.expirationDate.value.slice(0, 2) +
          '/20' +
          this.ccForm.controls.expirationDate.value.slice(2, 4),
        securityCode: this.ccForm.controls.securityCode.value,
        cardType: this.isCredit ? 'credit' : 'debit',
        orderNumber: (
          this.establishment.id.toString() +
          this._sharedFunctionsService.generateRandomLetter() +
          this.paymentLink.orderNumber
        ).substring(0, 16),
        customerName: this.paymentLink.customerName,
        purchaseValueEUR: this.paymentLink.valueEUR,
        totalValueEUR: this.transaction.totalValueEUR,
        totalValueBRL: 100 * this.transaction.installmentValueBRL * this.ccForm.controls.parcelas.value,
        installmentValueBRL: this.transaction.installmentValueBRL,
        paymentMethod: this.transaction.paymentMethod,
        installments: this.ccForm.controls.parcelas.value,
        readMethod: this.transaction.readMethod,
        cpFofCardOwner: this.ccForm.controls.cpf.value,
        cpfName: '-',
        exchangeRate: this.transaction.exchangeRate,
        paymentLink: this.paymentLink.id,
        customerEmail: this.paymentLink.customerEmail,
        currencyCode: this.establishment.currencyCode,
        customerPhoneNumber: '-',
        customerAddress: '-',
        customerZipCode: '-',
        customerCity: '-',
        customerCountryID: 0,
        customerCPFCNPJ: '-',
        cardProcessingComission: this.transaction.cardProcessingComission / 100,
        estimatedExchangeCostBRL: this.transaction.estimatedExchangeCostBRL,
        cardProcessingCostBRL: this.transaction.cardProcessingCostBRL / 100,
        maxAntecipationCostBRL: this.transaction.maxAntecipationCostBRL,
        pdfUrl: this.paymentLink.pdfUrl || ''
      };

      // Set the payment in the store
      this._storeService.updateState({ dreampayPayment: payment });

      this._logger.context('payment', this.dreampayPayment);

      this._paymentsService.apiPaymentsNewPost(this.dreampayPayment).subscribe(
        response => {
          // Store the payment response
          this._storeService.updateState({ paymentResponse: response });
          // Also update PaymentId in the store
          this._storeService.updateState({ PaymentId: response.paymentID });
          if (this.paymentResponse.returnCode === '00') {
            this._storeService.setFinishStepMessage('Pagamento efetuado com sucesso.');
          } else {
            this._storeService.setFinishStepMessage('Pagamento não autorizado.' + response.returnMessage);
          }
          this._logger.context('paymentResponse', this.paymentResponse);
          this.sendVouchersAndRedirect(this.paymentResponse.returnCode === '00', !this.isCredit);
        },
        error => {
          this._storeService.setFinishStepMessage('Ocorreu um erro ao processar o pagamento.');
          this.sendVouchersAndRedirect(false, !this.isCredit);
          this._logger.context('error', error);
        }
      );
    } else {
      // Implementar fluxo de pagamento de PIX
      this._storeService.setFinishStepMessage('Utilize o código PIX para realizar o pagamento');
      this._storeService.updateState({ showPixStep: true });
    }
  }

  sendVouchersAndRedirect(paid: boolean, debit: boolean) {
    if (paid) {
      if (this.paymentLink.linkType !== 2) {
        this._sharedFunctionsService.sendVouchers(
          this.paymentResponse?.paymentID,
          this.establishment,
          this.paymentLink
        );
      }
    }
    if (this.paymentLink.returnUrl && paid) {
      this._storeService.setFinishStepMessage(this._storeService.getFinishStepMessage() + ' A redirecionar...');
      window.setTimeout(redirect, 3000, this.paymentLink?.returnUrl);
    }
  }
}

function expDateValidators(c: FormControl) {
  const monthAndYear = /^(0[1-9]|1[0-2])\/?([2-9][0-9])$/;

  return monthAndYear.test(c.value)
    ? null
    : {
        validateInput: {
          valid: false
        }
      };
}

function redirect(returnUrl) {
  window.location.href = returnUrl;
}
