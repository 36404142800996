import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CardIssuerFees,
  DreampayPayment,
  DreampayPaymentResponse,
  Establishments,
  PaymentLinks,
  Settings
} from 'api-client';
import { Transaction } from '../models/transaction.class';
import { NewNotification } from '../models/notification.class';

/**
 * Global state interface for the application
 */
export interface StoreState {
  // Common data
  linkId?: string; // ID used to fetch payment link data
  paymentLink?: PaymentLinks;
  establishment?: Establishments;

  // Payment processing data
  transaction?: Transaction;
  dreampayPayment?: DreampayPayment;
  paymentResponse?: DreampayPaymentResponse;

  // UI state
  finishStepMessage?: string;
  showPixStep?: boolean;
  isCredit?: boolean;

  // Payment details
  installmentValueBRL?: number;

  // Card details
  cardImgPath?: string;
  cardIssuerFees?: CardIssuerFees;
  cardType?: string;

  // Payment transaction details
  paymentID?: string; // Used in Transaction class (uppercase ID)
  PaymentId?: string; // Used in response models (uppercase P and I)
  tid?: string;
  authorizationCode?: string;
  proofSale?: string;
  paymentAccountReference?: string;

  // Payment status
  paid?: boolean;
  canceled?: boolean;
  cancelRequested?: boolean;
  cancelRequestDate?: string;
  canceledDate?: string;
  canceledTID?: string;
  canceledAuthorizationCode?: string;
  canceledProofSale?: string;

  // Transaction response
  returnCode?: string;
  returnMessage?: string;

  // Financial details
  exchangeCostBRL?: number;
  cardProcessingComission?: number;
  cardProcessingCostBRL?: number;
  antecipationCostBRL?: number;
  operationalMarginBRL?: number;

  // Settings and configuration
  settings?: Settings;
  exchangeRate?: number;

  // Notifications
  failedPayNotification?: NewNotification;

  // Payment options
  creditOnly?: boolean;
  minimumInstallments?: number;
  defaultInstallments?: number;

  // Checkout specific data
  customerName?: string;
  customerEmail?: string;
  customerAddress?: string;
  customerCity?: string;
  customerZipCode?: string;
  customerPhoneNumber?: string;
  customerCPFCNPJ?: string;

  // Form data
  cardNumber?: string;
  cardHolder?: string;
  cpf?: string;
  expirationDate?: string;
  securityCode?: string;
  installments?: number;

  // GRERJ specific data
  pdfUrl?: string;
}

/**
 * Global store service for the application
 * Provides a simple yet robust state management solution
 */
@Injectable({
  providedIn: 'root'
})
export class StoreService {
  // Initial state
  private initialState: StoreState = {
    showPixStep: false,
    isCredit: true,
    creditOnly: false,
    minimumInstallments: 1,
    defaultInstallments: 12,
    installments: 1
  };

  // BehaviorSubject to hold the current state
  private state$ = new BehaviorSubject<StoreState>(this.initialState);

  constructor() {}

  /**
   * Get the current state value
   */
  get currentState(): StoreState {
    return this.state$.getValue();
  }

  /**
   * Get the state as an observable
   */
  get state(): Observable<StoreState> {
    return this.state$.asObservable();
  }

  /**
   * Select a specific part of the state
   * @param selector Function to select part of the state
   */
  select<T>(selector: (state: StoreState) => T): Observable<T> {
    return this.state$.pipe(map(selector));
  }

  /**
   * Update the state
   * @param newState Partial state to merge with current state
   */
  updateState(newState: Partial<StoreState>): void {
    this.state$.next({
      ...this.currentState,
      ...newState
    });
  }

  /**
   * Reset the state to initial values
   */
  resetState(): void {
    this.state$.next(this.initialState);
  }

  /**
   * Reset specific parts of the state
   * @param keys Keys to reset to initial values
   */
  resetStateProperties(...keys: (keyof StoreState)[]): void {
    this.updateState(Object.fromEntries(keys.map(key => [key, undefined])));
  }

  /**
   * Clear sensitive card data
   */
  clearSensitiveData(): void {
    this.updateState({
      cardNumber: undefined,
      cardHolder: undefined,
      cpf: undefined,
      expirationDate: undefined,
      securityCode: undefined
    });
  }

  /**
   * Log state information to the console
   * @param message Optional message to include with the log
   * @param properties Optional specific properties to log (logs all state if not specified)
   */
  logState(message?: string, ...properties: (keyof StoreState)[]): void {
    const state = this.currentState;
    const timestamp = new Date().toISOString();

    if (message) {
      console.log(`[StoreService ${timestamp}] ${message}`);
    } else {
      console.log(`[StoreService ${timestamp}] Current State:`);
    }

    if (properties && properties.length > 0) {
      // Log only specified properties
      const selectedState = properties.reduce<Record<string, any>>((result, prop) => {
        result[prop as string] = state[prop];
        return result;
      }, {});
      console.log(selectedState);
    } else {
      // Log entire state
      console.log(state);
    }
  }

  // Common data

  /**
   * Set the link ID
   * @param linkId Link ID
   */
  setLinkId(linkId: string): void {
    this.updateState({ linkId });
  }

  /**
   * Get the link ID
   */
  getLinkId(): string {
    return this.currentState.linkId;
  }

  // Payment Link and Establishment

  /**
   * Set the payment link
   * @param paymentLink Payment link data
   */
  setPaymentLink(paymentLink: PaymentLinks): void {
    this.updateState({
      paymentLink,
      customerName: paymentLink.customerName,
      customerEmail: paymentLink.customerEmail,
      pdfUrl: paymentLink.pdfUrl
    });
  }

  /**
   * Set the establishment
   * @param establishment Establishment data
   */
  setEstablishment(establishment: Establishments): void {
    this.updateState({
      establishment
    });
  }

  /**
   * Get the payment link
   */
  getPaymentLink(): PaymentLinks {
    return this.currentState.paymentLink;
  }

  /**
   * Get the establishment
   */
  getEstablishment(): Establishments {
    return this.currentState.establishment;
  }

  // Payment Processing

  /**
   * Set transaction data
   * @param transaction Transaction data
   */
  setTransaction(transaction: Transaction): void {
    this.updateState({ transaction });
  }

  /**
   * Update transaction data
   * @param transactionData Partial transaction data
   */
  updateTransaction(transactionData: Partial<Transaction>): void {
    const currentTransaction = this.currentState.transaction || new Transaction();

    this.updateState({
      transaction: {
        ...currentTransaction,
        ...transactionData
      }
    });
  }

  /**
   * Set Dreampay payment data
   * @param dreampayPayment Payment data
   */
  setDreampayPayment(dreampayPayment: DreampayPayment): void {
    this.updateState({ dreampayPayment });
  }

  /**
   * Set payment response
   * @param paymentResponse Payment response data
   */
  setPaymentResponse(paymentResponse: DreampayPaymentResponse): void {
    this.updateState({ paymentResponse });
  }

  // UI State

  /**
   * Set finish step message
   * @param message Message to display
   */
  setFinishStepMessage(message: string): void {
    this.updateState({ finishStepMessage: message });
  }

  /**
   * Get finish step message
   */
  getFinishStepMessage(): string {
    return this.currentState.finishStepMessage;
  }

  /**
   * Toggle PIX step visibility
   * @param show Whether to show PIX step
   */
  togglePixStep(show: boolean): void {
    this.updateState({ showPixStep: show });
  }

  /**
   * Set PIX step visibility
   * @param show Whether to show PIX step
   */
  setShowPixStep(show: boolean): void {
    this.updateState({ showPixStep: show });
  }

  /**
   * Get PIX step visibility
   */
  getShowPixStep(): boolean {
    return this.currentState.showPixStep;
  }

  /**
   * Toggle credit/debit payment method
   * @param isCredit Whether credit is selected
   */
  toggleCreditDebit(isCredit: boolean): void {
    this.updateState({ isCredit });
  }

  // Card Details

  /**
   * Set card image path
   * @param cardImgPath Path to card image
   */
  setCardImagePath(cardImgPath: string): void {
    this.updateState({ cardImgPath });
  }

  /**
   * Set card issuer fees
   * @param cardIssuerFees Card issuer fees
   */
  setCardIssuerFees(cardIssuerFees: CardIssuerFees): void {
    this.updateState({ cardIssuerFees });
  }

  // Settings

  /**
   * Set application settings
   * @param settings Application settings
   */
  setSettings(settings: Settings): void {
    this.updateState({ settings });
  }

  /**
   * Set exchange rate
   * @param exchangeRate Exchange rate value
   */
  setExchangeRate(exchangeRate: number): void {
    this.updateState({ exchangeRate });
  }

  // Notifications

  /**
   * Set failed payment notification
   * @param notification Notification data
   */
  setFailedPayNotification(notification: NewNotification): void {
    this.updateState({ failedPayNotification: notification });
  }

  // Payment Options

  /**
   * Set credit only flag
   * @param creditOnly Whether only credit is allowed
   */
  setCreditOnly(creditOnly: boolean): void {
    this.updateState({ creditOnly });
  }

  /**
   * Set minimum installments
   * @param minimumInstallments Minimum number of installments
   */
  setMinimumInstallments(minimumInstallments: number): void {
    this.updateState({ minimumInstallments });
  }

  /**
   * Set default installments
   * @param defaultInstallments Default number of installments
   */
  setDefaultInstallments(defaultInstallments: number): void {
    this.updateState({ defaultInstallments });
  }

  /**
   * Set number of installments
   * @param installments Number of installments
   */
  setInstallments(installments: number): void {
    this.updateState({ installments });
  }

  // Customer Data

  /**
   * Set customer data
   * @param customerData Customer data
   */
  setCustomerData(customerData: {
    customerName?: string;
    customerEmail?: string;
    customerAddress?: string;
    customerCity?: string;
    customerZipCode?: string;
    customerPhoneNumber?: string;
    customerCPFCNPJ?: string;
  }): void {
    this.updateState(customerData);
  }

  // Form Data

  /**
   * Set card form data
   * @param cardData Card form data
   */
  setCardFormData(cardData: {
    cardNumber?: string;
    cardHolder?: string;
    cpf?: string;
    expirationDate?: string;
    securityCode?: string;
  }): void {
    this.updateState(cardData);
  }
}
