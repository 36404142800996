import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';

import { PaymentComponent } from './payment.component';
import { ConfirmStepComponent } from '../../components/confirm-step/confirm-step.component';
import { PaymentStepComponent } from '../../components/payment-step/payment-step.component';
import { FinishStepComponent } from '../../components/finish-step/finish-step.component';
import { PixStepComponent } from '../../components/pix-step/pix-step.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, MaterialModule],
  exports: [MaterialModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PaymentModule {}
