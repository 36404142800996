<mat-horizontal-stepper linear #stepper>
  <mat-step>
    <ng-template matStepLabel>Confirmar</ng-template>
    <confirm-step
      [paymentLink]="paymentLink"
      [establishment]="establishment"
      *ngIf="paymentLink && establishment"></confirm-step>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Pagar</ng-template>
    <payment-step *ngIf="paymentLink && establishment && !showPixStep"></payment-step>
  </mat-step>

  <mat-step *ngIf="showPixStep">
    <ng-template matStepLabel>Pix</ng-template>

    <pix-step *ngIf="showPixStep"></pix-step>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Concluído</ng-template>

    <finish-step [message]="finishStepMessage"></finish-step>
  </mat-step>
</mat-horizontal-stepper>
