import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StepperService {
  // Subject to emit stepper navigation events
  private stepperNavigationSubject = new Subject<string>();

  // Observable that components can subscribe to
  stepperNavigation$ = this.stepperNavigationSubject.asObservable();

  constructor() {}

  /**
   * Navigate to the next step in the stepper
   */
  next() {
    this.stepperNavigationSubject.next('next');
  }

  /**
   * Navigate to a specific step in the stepper by index
   * @param index The zero-based index of the step to navigate to
   */
  goToStep(index: number) {
    this.stepperNavigationSubject.next(`goto:${index}`);
  }

  /**
   * Navigate to the final step in the stepper
   */
  goToFinalStep() {
    this.stepperNavigationSubject.next('final');
  }
}
