<form [formGroup]="ccForm" (ngSubmit)="submeter()" *ngIf="ccForm">
  <div class="center">
    <div class="card my-4">
      <div class="flip">
        <div class="front">
          <div class="strip-bottom"></div>
          <div class="strip-top"></div>
          <div class="investor">{{ bankName }}</div>
          <div class="chip">
            <div class="chip-line"></div>
            <div class="chip-line"></div>
            <div class="chip-line"></div>
            <div class="chip-line"></div>
            <div class="chip-main"></div>
          </div>
          <svg class="wave" viewBox="0 3.71 26.959 38.787" width="26.959" height="38.787" fill="white">
            <path
              d="M19.709 3.719c.266.043.5.187.656.406 4.125 5.207 6.594 11.781 6.594 18.938 0 7.156-2.469 13.73-6.594 18.937-.195.336-.57.531-.957.492a.9946.9946 0 0 1-.851-.66c-.129-.367-.035-.777.246-1.051 3.855-4.867 6.156-11.023 6.156-17.718 0-6.696-2.301-12.852-6.156-17.719-.262-.317-.301-.762-.102-1.121.204-.36.602-.559 1.008-.504z"></path>
            <path
              d="M13.74 7.563c.231.039.442.164.594.343 3.508 4.059 5.625 9.371 5.625 15.157 0 5.785-2.113 11.097-5.625 15.156-.363.422-1 .472-1.422.109-.422-.363-.472-1-.109-1.422 3.211-3.711 5.156-8.551 5.156-13.843 0-5.293-1.949-10.133-5.156-13.844-.27-.309-.324-.75-.141-1.114.188-.367.578-.582.985-.542h.093z"></path>
            <path
              d="M7.584 11.438c.227.031.438.144.594.312 2.953 2.863 4.781 6.875 4.781 11.313 0 4.433-1.828 8.449-4.781 11.312-.398.387-1.035.383-1.422-.016-.387-.398-.383-1.035.016-1.421 2.582-2.504 4.187-5.993 4.187-9.875 0-3.883-1.605-7.372-4.187-9.875-.321-.282-.426-.739-.266-1.133.164-.395.559-.641.984-.617h.094zM1.178 15.531c.121.02.238.063.344.125 2.633 1.414 4.437 4.215 4.437 7.407 0 3.195-1.797 5.996-4.437 7.406-.492.258-1.102.07-1.36-.422-.257-.492-.07-1.102.422-1.359 2.012-1.075 3.375-3.176 3.375-5.625 0-2.446-1.371-4.551-3.375-5.625-.441-.204-.676-.692-.551-1.165.122-.468.567-.785 1.051-.742h.094z"></path>
          </svg>
          <div class="card-number">
            <div class="section">{{ this.bloco1 }}</div>
            <div class="section">{{ this.bloco2 }}</div>
            <div class="section">{{ this.bloco3 }}</div>
            <div class="section">{{ this.bloco4 }}</div>
            <div class="section">{{ this.bloco5 }}</div>
          </div>
          <div class="end">
            <span class="end-text">valid till:</span
            ><span class="end-date">
              {{ ccForm.controls.expirationDate.value.slice(0, 2)
              }}{{ ccForm.controls.expirationDate.value.length > 2 ? '/' : ''
              }}{{ ccForm.controls.expirationDate.value.slice(2, 4) }}</span
            >
          </div>
          <div class="card-holder">{{ ccForm.controls.name.value }}</div>
          <div class="master">
            <img [src]="cardImgPath" alt="" />
          </div>
        </div>
        <div class="back">
          <div class="strip-black"></div>
          <div class="ccv">
            <label>ccv</label>
            <div>{{ ccForm.controls.securityCode.value }}</div>
          </div>
          <div class="terms">
            <p>
              This card is property of the emitting Bank. Misuse is criminal offence. If found, please return to the
              Bank or to the nearest bank with MasterCard logo.
            </p>
            <p>Use of this card is subject to the credit card agreement.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="cards-bar mb-4">
      <div class="flex flex-row gap-4">
        <div class="flex flex-col mb-4">
          <label for="name" class="block text-gray-700 text-sm font-bold mb-2">Nome</label>
          <input
            id="name"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="name"
            type="text"
            maxlength="26" />
        </div>

        <div class="flex flex-col mb-4">
          <label for="cpf" class="block text-gray-700 text-sm font-bold mb-2">CPF do titular</label>
          <input
            id="cpf"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="cpf"
            type="text"
            mask="000.000.000-00"
            required />
        </div>
      </div>

      <div class="flex flex-row justify-between">
        <div class="flex flex-row mb-4" class="block text-gray-700 text-sm font-bold mb-2">
          <label for="isCredit" class="block text-gray-700 text-sm font-bold mb-2">Tipo</label>

          <mat-button-toggle-group
            class="payment-toggle w-full"
            name="fontStyle"
            aria-label="Font Style"
            (change)="onRadioChange($event)">
            <mat-button-toggle value="1" [checked]="isCredit">Crédito</mat-button-toggle>
            <mat-button-toggle value="2" [checked]="!isCredit">Pix</mat-button-toggle>
          </mat-button-toggle-group>
        </div>

        <div class="flex flex-col mb-4">
          <label
            for="parcelas"
            [style.display]="isCredit ? '' : 'none'"
            class="block text-gray-700 text-sm font-bold mb-2"
            >Número de Parcelas</label
          >
          <select
            name="parcelas"
            id="parcelas"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="parcelas"
            #mySelect
            (change)="onOptionsSelected(mySelect.value)"
            [style.display]="isCredit ? '' : 'none'">
            <option value="1" *ngIf="1 >= minimumInstallments">1</option>
            <option value="2" *ngIf="2 >= minimumInstallments">2</option>
            <option value="3" *ngIf="3 >= minimumInstallments">3</option>
            <option value="4" *ngIf="4 >= minimumInstallments">4</option>
            <option value="5" *ngIf="5 >= minimumInstallments">5</option>
            <option value="6" *ngIf="6 >= minimumInstallments">6</option>
            <option value="7" *ngIf="7 >= minimumInstallments">7</option>
            <option value="8" *ngIf="8 >= minimumInstallments">8</option>
            <option value="9" *ngIf="9 >= minimumInstallments">9</option>
            <option value="10" *ngIf="10 >= minimumInstallments">10</option>
            <option value="11" *ngIf="11 >= minimumInstallments">11</option>
            <option value="12" *ngIf="12 >= minimumInstallments">12</option>
          </select>
        </div>
      </div>

      <div class="flex flex-col mb-4" *ngIf="isCredit">
        <label for="cardNumber" class="block text-gray-700 text-sm font-bold mb-2">Número do cartão</label>
        <input
          id="cardNumber"
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          formControlName="cardNumber"
          type="text"
          inputmode="numeric"
          [class.error]="getCardNumberControl().dirty && getCardNumberControl().invalid"
          [textMask]="{
            mask: cardMaskFunction,
            guide: false,
            showMask: true
          }"
          (blur)="onCardNumberBlur()" />

        <div *ngIf="getCardNumberControl().dirty">
          <p [class.invalid]="getCardNumberControl().invalid"></p>
          <p [class.valid]="getCardNumberControl().valid"></p>
        </div>
      </div>

      <div class="flex flex-row gap-4" *ngIf="isCredit">
        <div class="flex flex-col mb-4">
          <label for="expirationDate" class="block text-gray-700 text-sm font-bold mb-2">Data de Validade</label>
          <input
            id="expirationDate"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="expirationDate"
            type="text"
            inputmode="numeric"
            [class.error]="getExpirationDateControl().dirty && getExpirationDateControl().invalid"
            placeholder="mm/aa"
            mask="99/99" />
        </div>
        <div class="flex flex-col mb-4">
          <label for="securityCode" class="block text-gray-700 text-sm font-bold mb-2">Código de Segurança</label>
          <input
            id="securityCode"
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            formControlName="securityCode"
            type="text"
            inputmode="numeric"
            maxlength="4"
            onkeydown="return ( event.ctrlKey || event.altKey
                    || (47<event.keyCode && event.keyCode<58 && event.shiftKey==false)
                    || (95<event.keyCode && event.keyCode<106)
                    || (event.keyCode==8) || (event.keyCode==9)
                    || (event.keyCode>34 && event.keyCode<40)
                    || (event.keyCode==46) )" />
        </div>
      </div>

      <div class="flex flex-row justify-between">
        <button
          matStepperPrevious
          class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
          Voltar
        </button>

        <button
          class="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
          matStepperNext
          [disabled]="!ccForm.valid">
          Pagar R${{ ccForm.controls.totalToPay.value }}
          {{ ccForm.controls.parcelas.value === 1 ? '' : ' x ' + ccForm.controls.parcelas.value }}
        </button>
      </div>
    </div>

    <div class="cards-bar flex flex-row justify-between">
      <div class="text-xs">Informação tratada pela Rede e não fornecida ao comerciante.</div>
      <img src="../../../assets/img/ssl.png" />
    </div>
  </div>
</form>
