import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StoreService } from '../../services/store.service';
import { PixService, PixTransactionRequest } from 'api-client';
import { SignalRService, PaymentNotification } from '../../services/signalr.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StepperService } from '../../services/stepper.service';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'pix-step',
  templateUrl: './pix-step.component.html',
  styleUrls: ['./pix-step.component.scss']
})
export class PixStepComponent implements OnInit, OnDestroy {
  message: string = 'Escaneie o QR Code abaixo para realizar o pagamento via PIX';
  finishForm: FormGroup;
  pixQrCode: string = '';
  pixCopyPaste: string = '';

  isLoading: boolean = true;
  hasError: boolean = false;
  errorMessage: string = '';
  transactionId: string = '';
  qrCodeExpiration: string = '';
  connectionStatus: string = 'Connecting...';
  environment = environment; // Make environment available to the template
  paymentLinkId: string = ''; // Make paymentLinkId public for debugging
  notificationReceived: boolean = false;

  private paymentNotificationSubscription: Subscription;

  constructor(
    private _pixService: PixService,
    private _storeService: StoreService,
    private _signalRService: SignalRService,
    private _stepperService: StepperService,
    private _sharedFunctionsService: SharedFunctionsService
  ) {}

  ngOnInit(): void {
    this.generatePixQrCode();
    this.startSignalRConnection();
  }

  ngOnDestroy(): void {
    // Unsubscribe to avoid memory leaks
    if (this.paymentNotificationSubscription) {
      this.paymentNotificationSubscription.unsubscribe();
    }

    // Stop SignalR connection when component is destroyed
    this._signalRService.stopConnection();
  }

  private startSignalRConnection(): void {
    // Start the SignalR connection
    this.connectionStatus = 'Connecting...';

    this._signalRService.startConnection().then(
      () => {
        this.connectionStatus = 'Connected';

        // Subscribe to payment notifications
        this.paymentNotificationSubscription = this._signalRService
          .getPaymentNotifications()
          .subscribe(notification => {
            if (notification) {
              this.handlePaymentNotification(notification);
            }
          });
      },
      err => {
        this.connectionStatus = 'Connection failed';
      }
    );
  }

  private handlePaymentNotification(notification: PaymentNotification): void {
    // Get values from notification
    const status = notification.status;
    const paymentLinkId = notification.paymentLinkId;
    const transactionId = notification.transactionId;

    // Get stored transaction ID from the store
    const storedTid = this._storeService.currentState.tid;

    // Check if the notification is for this payment
    // Validate: status is 'Paid', paymentLinkId matches, and transactionId matches
    if (
      paymentLinkId === this.paymentLinkId &&
      status === 'Paid' &&
      (transactionId === this.transactionId || transactionId === storedTid) &&
      !this.notificationReceived
    ) {
      this.notificationReceived = true;
      // Update transaction status in store if needed
      this._storeService.updateState({
        paid: true,
        tid: transactionId
      });

      this._sharedFunctionsService.sendVouchers(
        this._storeService.currentState.tid,
        this._storeService.currentState.establishment,
        this._storeService.currentState.paymentLink
      );

      // Set success message for the final step
      this._storeService.setFinishStepMessage('Pagamento PIX efetuado com sucesso.');

      // Navigate to the final step in the stepper
      this._stepperService.goToFinalStep();
    }
  }

  // Reconnect SignalR if needed
  reconnectSignalR(): void {
    this._signalRService.stopConnection().then(() => {
      setTimeout(() => {
        this.startSignalRConnection();
      }, 1000);
    });
  }

  generatePixQrCode(): void {
    this.isLoading = true;
    this.hasError = false;

    // Get the transaction data from the store service
    const paymentLink = this._storeService.getPaymentLink();
    const transaction = this._storeService.currentState.transaction;
    const dreampayPayment = this._storeService.currentState.dreampayPayment;

    if (!transaction || !paymentLink) {
      this.handleError('Dados de transação não encontrados');
      return;
    }

    // Store the payment link ID for later comparison with notifications
    this.paymentLinkId = paymentLink.id;

    // Create the PIX transaction request
    const pixRequest: PixTransactionRequest = {
      kind: 'Pix',
      reference: paymentLink.orderNumber,
      amount: Math.round(parseFloat(transaction.totalValueBRL.toFixed(2)) * 100),
      qrCode: {
        dateTimeExpiration: new Date(Date.now() + 24 * 60 * 60 * 1000)
          .toISOString()
          .replace(/(\.\d{3})Z$/, '')
          .replace(/(:\d{2})\./, '$1')
      },
      establishmentID: paymentLink.establishmentID,
      purchaseAmount: paymentLink.value,
      purchaseCurrency: paymentLink.currencyCode,
      paymentLinkID: paymentLink.id,
      posID: '',
      cpf: this._storeService.currentState.cpf || '',
      exchangeRate: dreampayPayment?.exchangeRate || transaction.exchangeRate || 1,
      cardProcessingComission: dreampayPayment?.cardProcessingComission || transaction.cardProcessingComission || 0,
      estimatedExchangeCostBRL: dreampayPayment?.estimatedExchangeCostBRL || transaction.estimatedExchangeCostBRL || 0,
      cardProcessingCostBRL: dreampayPayment?.cardProcessingCostBRL || transaction.cardProcessingCostBRL || 0
    };

    // Call the PIX service to generate a QR code
    this._pixService.apiPixPost(pixRequest).subscribe(
      response => {
        this.isLoading = false;

        if (response && response.qrCodeResponse) {
          // Store the QR code data
          this.pixQrCode = response.qrCodeResponse.qrCodeImage || '';
          this.pixCopyPaste = response.qrCodeResponse.qrCodeData || '';
          this.transactionId = response.tid || '';
          this.qrCodeExpiration = pixRequest.qrCode.dateTimeExpiration;

          // Set success message
          this.message = 'Escaneie o QR Code para realizar o pagamento via PIX';

          // Save the complete PIX response to the store
          this._storeService.updateState({
            tid: this.transactionId,
            paymentResponse: response
          });

          // Update transaction with PIX info
          if (transaction) {
            this._storeService.updateTransaction({
              tid: this.transactionId
            });
          }
        } else {
          this.handleError('Resposta inválida do servidor');
        }
      },
      error => {
        this.handleError('Erro ao gerar QR Code PIX. Por favor, tente novamente.');
      }
    );
  }

  copyPixCode(): void {
    if (this.pixCopyPaste) {
      navigator.clipboard.writeText(this.pixCopyPaste).then(
        () => {
          // Show a success message or toast
        },
        err => {
          console.error('Could not copy text: ', err);
        }
      );
    }
  }

  private handleError(message: string): void {
    this.isLoading = false;
    this.hasError = true;
    this.errorMessage = message;
  }

  retryGeneration(): void {
    this.generatePixQrCode();
  }
}
