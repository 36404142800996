import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CanceledLinkComponent } from './pages/canceled-link/canceled-link.component';
import { ExpiredLinkComponent } from './pages/expired-link/expired-link.component';
import { InvalidLinkComponent } from './pages/invalid-link/invalid-link.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { PaidLinkComponent } from './pages/paid-link/paid-link.component';
import { PaymentCheckoutComponent } from './pages/payment-checkout/payment-checkout.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { PaymentGrerjComponent } from './pages/payment-grerj/payment-grerj.component';

const routes: Routes =
  environment.env === 'local'
    ? [
        { path: 'pay/:id', component: PaymentComponent },
        { path: 'maintenance', component: MaintenanceComponent },
        { path: 'invalid-link', component: InvalidLinkComponent },
        { path: 'expired-link', component: ExpiredLinkComponent },
        { path: 'canceled-link', component: CanceledLinkComponent },
        { path: 'paid-link', component: PaidLinkComponent },
        { path: 'pay-checkout/:id', component: PaymentCheckoutComponent },
        { path: 'pay-grerj/:id', component: PaymentGrerjComponent },

        {
          path: '**',
          redirectTo: '/pay/117e4c8a-1a29-4f56-9dc4-866abc6a2b93',
          pathMatch: 'full'
        }
      ]
    : [
        { path: 'pay/:id', component: PaymentComponent },
        { path: 'maintenance', component: MaintenanceComponent },
        { path: 'invalid-link', component: InvalidLinkComponent },
        { path: 'expired-link', component: ExpiredLinkComponent },
        { path: 'canceled-link', component: CanceledLinkComponent },
        { path: 'paid-link', component: PaidLinkComponent },
        { path: 'pay-checkout/:id', component: PaymentCheckoutComponent },
        { path: 'pay-grerj/:id', component: PaymentGrerjComponent }
      ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
