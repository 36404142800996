<div class="pix-container">
  <div class="center">
    <div class="cards-bar my-4">
      <h2 class="text-xl font-bold mb-4 text-center">Pagamento via PIX</h2>

      <!-- Loading state -->
      <div *ngIf="isLoading" class="loading-container text-center py-8">
        <div class="spinner mb-4"></div>
        <p>Gerando QR Code PIX...</p>
      </div>

      <!-- Error state -->
      <div *ngIf="hasError" class="error-container text-center py-8">
        <div class="error-icon mb-4">
          <i class="material-icons text-red-500 text-4xl">error_outline</i>
        </div>
        <p class="text-red-500 mb-4">{{ errorMessage }}</p>
        <button
          (click)="retryGeneration()"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Tentar novamente
        </button>
      </div>

      <!-- Success state -->
      <div *ngIf="!isLoading && !hasError" class="success-container">
        <p class="text-center mb-4">{{ message }}</p>

        <!-- QR Code -->
        <div class="qr-code-container text-center mb-6">
          <img
            *ngIf="pixQrCode"
            [src]="'data:image/png;base64,' + pixQrCode"
            alt="QR Code PIX"
            class="mx-auto border p-2 rounded"
            style="max-width: 250px; max-height: 250px" />
        </div>

        <!-- Expiration info -->
        <div *ngIf="qrCodeExpiration" class="expiration-info text-center mb-4">
          <p class="text-sm">Código válido até: {{ qrCodeExpiration | date : 'dd/MM/yyyy HH:mm' }}</p>
        </div>

        <!-- Copy paste code -->
        <div *ngIf="pixCopyPaste" class="copy-paste-container mb-6">
          <p class="text-center mb-2 text-sm font-bold">Ou copie o código PIX abaixo:</p>
          <div class="flex items-center justify-center">
            <div class="pix-code border rounded p-2 bg-gray-100 text-xs overflow-hidden text-ellipsis w-full">
              {{ pixCopyPaste }}
            </div>
            <button
              (click)="copyPixCode()"
              class="ml-2 bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-3 rounded">
              <i class="material-icons text-sm">content_copy</i>
            </button>
          </div>
        </div>

        <div class="instructions text-center text-sm">
          <p class="mb-2">1. Abra o aplicativo do seu banco</p>
          <p class="mb-2">2. Escolha a opção de pagamento via PIX</p>
          <p class="mb-2">3. Escaneie o QR code ou cole o código PIX</p>
          <p class="mb-2">4. Confirme as informações e finalize o pagamento</p>
        </div>
      </div>
    </div>
  </div>
</div>
