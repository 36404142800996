import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { interval, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CallbackMessage } from '../models/callback-message.model';
import { Translations } from '../models/translations.model';
import { LogService } from './log.service';
import { CountriesService, Establishments, NotificationsService, PaymentLinks } from 'api-client';
import { NewNotification } from '../models/notification.class';
import { Transaction } from '../models/transaction.class';

@Injectable({
  providedIn: 'root'
})
export class SharedFunctionsService {
  private _counter: number = 0;
  private _translations: Translations = new Translations();

  constructor(
    private _httpClient: HttpClient,
    private _translate: TranslateService,
    private _logger: LogService,
    private _notificationsService: NotificationsService,
    private _countriesService: CountriesService
  ) {}

  convertBrasiliaDatetimeToUTC(datetime: string) {
    if (datetime && datetime !== '') {
      try {
        return new Date(datetime.replace('T', ' ').replace('Z', '') + ' GMT-3:00').toISOString();
      } catch {
        return new Date().toISOString();
      }
    } else {
      return new Date().toISOString();
    }
  }

  decrementCounter() {
    this._counter--;
  }

  generateRandomLetter() {
    const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    return alphabet[Math.floor(Math.random() * alphabet.length)];
  }

  getCounter() {
    return this._counter;
  }

  getTranslation(key: string) {
    return this._translations[key];
  }

  getTranslations() {
    return this._translations;
  }

  incrementCounter() {
    this._counter++;
  }

  initTranslations(countryCode: string): Observable<Translations> {
    this._translate.use(countryCode);

    return this._translate
      .get([
        'emailCustomer.hello',
        'emailCustomer.confirmationOrder',
        'emailCustomer.doneInEstablishment',
        'emailCustomer.deliveryProduct',
        'emailCustomer.voucherAttachment',
        'emailCustomer.thankYouForChoosingDreampay',

        'emailObs.greetingFromTeam',
        'emailObs.importantObs',
        'emailObs.emailAutomatic',
        'emailObs.inCaseNoVoucher',
        'emailObs.inCaseNoPurchase',
        'emailObs.emailSubject',

        'emailEstablishment.forEstablishment',
        'emailEstablishment.yourClientPurchased',
        'emailEstablishment.giveProductsToCustomer',
        'emailEstablishment.voucherAttached',
        'emailEstablishment.experienceSeeYouSoon'
      ])
      .pipe(
        map(translations => {
          this._translations.eCHello = translations['emailCustomer.hello'];
          this._translations.eCConfirmationOrder = translations['emailCustomer.confirmationOrder'];
          this._translations.eCdoneInEstablishment = translations['emailCustomer.doneInEstablishment'];
          this._translations.eCdeliveryProduct = translations['emailCustomer.deliveryProduct'];
          this._translations.eCvoucherAttachment = translations['emailCustomer.voucherAttachment'];
          this._translations.eCthankYouForChoosingDreampay = translations['emailCustomer.thankYouForChoosingDreampay'];

          this._translations.eOgreetings = translations['emailObs.greetingFromTeam'];
          this._translations.eOimportantObs = translations['emailObs.importantObs'];
          this._translations.eOemailAutomatic = translations['emailObs.emailAutomatic'];
          this._translations.eOinCaseNoVoucher = translations['emailObs.inCaseNoVoucher'];
          this._translations.eOinCaseNoPurchase = translations['emailObs.inCaseNoPurchase'];
          this._translations.emailSubjectString = translations['emailObs.emailSubject'];

          this._translations.eEforEstablishment = translations['emailEstablishment.forEstablishment'];
          this._translations.eEyourClientPurchased = translations['emailEstablishment.yourClientPurchased'];
          this._translations.eEgiveProductsToCustomer = translations['emailEstablishment.giveProductsToCustomer'];
          this._translations.eEvoucherAttached = translations['emailEstablishment.voucherAttached'];
          this._translations.eEexperienceSeeYouSoon = translations['emailEstablishment.experienceSeeYouSoon'];
          return this._translations;
        }),
        catchError(error => {
          this._logger.logToSentry('initTranslations error: ', error);
          throw error;
        })
      );
  }

  obfuscateCreditCardNumber(cardNumber: string) {
    return (
      cardNumber.substring(0, 7) + '*'.repeat(cardNumber.length - 10) + cardNumber.substring(cardNumber.length - 5)
    );
  }

  postToCallbackUrl(callBackUrl: string, callbackMessage: CallbackMessage) {
    let httpOptions = {};

    this._httpClient
      .post<any>(callBackUrl, callbackMessage, httpOptions)
      .pipe(take(1))
      .subscribe(response => {
        this._logger.logToSentry('postToCallbackUrl response: ', response);
      });
  }

  async getUiLanguageCode(countryId: number): Promise<string> {
    try {
      const res = await this._countriesService.apiCountriesIdGet(countryId).toPromise();
      return res.uiLanguage.trim().toLowerCase();
    } catch (err) {
      console.error(err);
      return 'pt';
    }
  }

  async sendAlertOpened(paymentLink, establishment, currSymbol) {
    if (paymentLink.customerName !== '') {
      let uiLanguage = await this.getUiLanguageCode(establishment.countryID);
      let notification: NewNotification = new NewNotification();
      notification.emailAddress = establishment.emailAddress;
      notification.cc = '';
      notification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
      notification.subject = uiLanguage === 'en' ? 'Payment Link Opened' : 'Link de pagamento aberto';
      notification.body =
        uiLanguage === 'en'
          ? 'Hello, ' + establishment.establismentName + '<br/>' + 'The customer ' + paymentLink.customerName ||
            '?' + ' with the order number ' + paymentLink.orderNumber + ' of ' + paymentLink.valueEUR.toString() ||
            '?' +
              currSymbol +
              ' has opened your payment link: ' +
              "<br/>Link: <a href='https://" +
              paymentLink.link +
              "' target='_blank'>https://" +
              paymentLink.link +
              '</a>' +
              "<br/><img src='" +
              paymentLink.qrCodeUrl +
              "' alt='qr-code' style='width: 250px; height:250px'>"
          : 'Olá, ' +
            establishment.establismentName +
            '<br/>' +
            'O cliente ' +
            paymentLink.customerName +
            ' com o nº de encomenda ' +
            paymentLink.orderNumber +
            ' de ' +
            paymentLink.valueEUR.toString() +
            currSymbol +
            ' abriu o seu link de pagamento: ' +
            "<br/>Link: <a href='https://" +
            paymentLink.link +
            "' target='_blank'>https://" +
            paymentLink.link +
            '</a>' +
            "<br/><img src='" +
            paymentLink.qrCodeUrl +
            "' alt='qr-code' style='width: 250px; height:250px'>";
      notification.emailTitle = uiLanguage === 'en' ? 'Payment Link Opened' : 'Link de pagamento aberto';
      notification.imageToUse = 'order-place.png';
      notification.language = uiLanguage;

      this._notificationsService
        .apiNotificationsPost(notification)
        .pipe(take(1))
        .subscribe(
          result => {
            this._logger.debug(result);
          },
          error => {
            this._logger.error(error);
          }
        );
    }
  }

  async sendAlertOpenedCheckout(
    paymentLink: PaymentLinks,
    establishment: Establishments,
    currSymbol: string,
    customerName: string,
    customerCPF: string,
    customerEmail: string,
    customerPhoneNumber: string,
    valueEUR: number
  ) {
    if (customerName !== '') {
      let notification: NewNotification = new NewNotification();
      let uiLanguage = await this.getUiLanguageCode(establishment.countryID);
      notification.emailAddress = establishment.emailAddress;
      notification.cc = '';
      notification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
      notification.subject = uiLanguage === 'en' ? 'Payment Link Opened' : 'Link de pagamento aberto';
      notification.body =
        uiLanguage === 'en'
          ? 'Hello, ' + establishment.establismentName + '<br/>' + ' The client ' + customerName ||
            '' + ' with the cpf: ' + customerCPF ||
            '' + ', email: ' + customerEmail ||
            '' + ' and phone number: ' + customerPhoneNumber ||
            '' +
              +' has opened your payment link: ' +
              "<br/>Link: <a href='https://" +
              paymentLink.link +
              "' target='_blank'>https://" +
              paymentLink.link +
              '</a>' +
              "<br/><img src='" +
              paymentLink.qrCodeUrl +
              "' alt='qr-code' style='width: 250px; height:250px'>" +
              ' with the value of ' +
              valueEUR.toString() +
              currSymbol
          : 'Olá, ' + establishment.establismentName + '<br/>' + 'O cliente ' + customerName ||
            '' + ' com o cpf: ' + customerCPF ||
            '' + ', email: ' + customerEmail ||
            '' + ' e número de telemóvel: ' + customerPhoneNumber ||
            '' +
              ' abriu o seu link de checkout de pagamento: ' +
              "<br/>Link: <a href='https://" +
              paymentLink.link +
              "' target='_blank'>https://" +
              paymentLink.link +
              '</a>' +
              "<br/><img src='" +
              paymentLink.qrCodeUrl +
              "' alt='qr-code' style='width: 250px; height:250px'>" +
              ' com o valor de ' +
              valueEUR.toString() +
              currSymbol;
      notification.emailTitle = uiLanguage === 'en' ? 'Payment Link Opened' : 'Link de pagamento aberto';
      notification.imageToUse = 'order-place.png';

      notification.language = uiLanguage;

      this._notificationsService
        .apiNotificationsPost(notification)
        .pipe(take(1))
        .subscribe(
          result => {
            this._logger.debug(result);
          },
          error => {
            this._logger.error(error);
          }
        );
    }
  }

  async sendFailedPayEmail(transaction: Transaction, establishment: Establishments, paymentLink: PaymentLinks) {
    let notification = new NewNotification();
    let uiLanguage = await this.getUiLanguageCode(establishment.countryID);
    notification.emailAddress = establishment.emailAddress;
    notification.cc = '';
    notification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
    notification.subject = uiLanguage === 'en' ? 'Failed payment From Link' : 'Falha no pagamento de Link';
    notification.body =
      uiLanguage === 'en'
        ? 'Hello, ' + transaction.establismentName + '<br/>' + 'The client ' + paymentLink.customerName ||
          '?' + ' with the order number ' + paymentLink.orderNumber ||
          '?' +
            ' of ' +
            transaction.purchaseValueEUR +
            transaction.currencyCode +
            ' has a failed payment with the link: ' +
            "<br/>Link: <a href='" +
            paymentLink.link +
            "' target='_blank'>" +
            paymentLink.link +
            '</a>' +
            "<br/><img src='" +
            paymentLink.qrCodeUrl +
            "' alt='qr-code' style='width: 250px; height:250px'>" +
            '<br/>' +
            ' Here is some information of the failed transaction: ' +
            '<br/>' +
            'Installments: ' +
            transaction.installments +
            '<br/>' +
            'Installment Value: ' +
            transaction.installmentValueBRL +
            '$R' +
            '<br/>' +
            'Payment ID: ' +
            transaction.paymentID +
            '<br/>' +
            'Card brand: ' +
            transaction.cardType +
            '<br/>'
        : 'Olá, ' +
          transaction.establismentName +
          '<br/>' +
          'O cliente ' +
          paymentLink.customerName +
          ' com o nº de encomenda ' +
          paymentLink.orderNumber +
          ' de ' +
          transaction.purchaseValueEUR +
          transaction.currencyCode +
          ' tem uma tentativa de pagamento falhada com o link: ' +
          "<br/>Link: <a href='" +
          paymentLink.link +
          "' target='_blank'>" +
          paymentLink.link +
          '</a>' +
          "<br/><img src='" +
          paymentLink.qrCodeUrl +
          "' alt='qr-code' style='width: 250px; height:250px'>" +
          '<br/>' +
          ' Aqui estão algumas informações da transação falhada: ' +
          '<br/>' +
          'Parcelas: ' +
          transaction.installments +
          '<br/>' +
          'Valor parcela: ' +
          transaction.installmentValueBRL +
          '$R' +
          '<br/>' +
          'ID Pagamento: ' +
          transaction.paymentID +
          '<br/>' +
          'Tipo Cartão: ' +
          transaction.cardType +
          '<br/>';
    notification.emailTitle = uiLanguage === 'en' ? 'Failed payment From Link' : 'Falha no pagamento de Link';
    notification.imageToUse = 'notification-warning.png';

    notification.language = uiLanguage;

    this._notificationsService
      .apiNotificationsPost(notification)
      .pipe(take(1))
      .subscribe(
        result => {
          this._logger.debug(result);
        },
        error => {
          this._logger.error(error);
        }
      );
  }

  async sendVouchers(paymentId: string, establishment: Establishments, paymentLink: PaymentLinks) {
    let notification = new NewNotification();
    const uiLanguage = await this.getUiLanguageCode(establishment.countryID);
    this.initTranslations(uiLanguage);
    notification.emailAddress = establishment.emailAddress;
    notification.cc = '';
    notification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
    notification.subject = this._translations.emailSubjectString;
    notification.body =
      '<div>' +
      this._translations.eEforEstablishment +
      ' ' +
      establishment.establismentName +
      '<br/><br/>' +
      this._translations.eEyourClientPurchased +
      paymentLink.orderNumber +
      this._translations.eEgiveProductsToCustomer +
      '<br/><br/>' +
      this._translations.eEvoucherAttached +
      '<br/>' +
      this._translations.eEexperienceSeeYouSoon +
      '<br/><br/>' +
      this._translations.eOgreetings +
      '</div>';
    notification.emailTitle = this._translations.emailSubjectString;
    notification.imageToUse = 'notification-gift-card.png';
    notification.language = uiLanguage;

    this._logger.context('Notification', { notification: notification });

    this._notificationsService
      .apiNotificationsVoucherPaymentIdPost(paymentId, notification)
      .pipe(take(1))
      .subscribe(
        result => {
          this._logger.debug('Voucher To Establishment sent');
        },
        error => {
          this._logger.error('Erro a enviar voucher para o estabelecimento..', error);
        }
      );

    interval(2000)
      .pipe(take(1))
      .subscribe(t => {
        this.sendVoucherToCustomer(paymentId, establishment, paymentLink);
      });
  }

  async sendVoucherToCustomer(paymentId: string, establishment: Establishments, paymentLink: PaymentLinks) {
    let notification = new NewNotification();
    let uiLanguage = await this.getUiLanguageCode(establishment.countryID);
    this.initTranslations(uiLanguage);
    notification.emailAddress = paymentLink.customerEmail;
    notification.subject = this._translations.emailSubjectString;
    notification.cc = '';
    notification.bcc = 'josematos@cloudworks.pt;leandronloyola@hotmail.com;waldyrvalente@dreampay.com.br';
    notification.body =
      '<div>' +
      this._translations.eCHello +
      '<br/><br/>' +
      this._translations.eCConfirmationOrder +
      paymentLink.orderNumber +
      this._translations.eCdoneInEstablishment +
      ' ' +
      establishment.establismentName +
      this._translations.eCdeliveryProduct +
      '<br/><br/>' +
      this._translations.eCvoucherAttachment +
      '<br/>' +
      this._translations.eCthankYouForChoosingDreampay +
      '<br/><br/>' +
      this._translations.eOgreetings +
      '</div>';
    notification.emailTitle = this._translations.emailSubjectString;
    notification.imageToUse = 'notification-gift-card.png';

    notification.language = uiLanguage;

    this._logger.context('Notification', { notification: notification });

    this._notificationsService
      .apiNotificationsVoucherCustomerPaymentIdPost(paymentId, notification)
      .pipe(take(1))
      .subscribe(
        result => {
          this._logger.debug('Voucher To Customer sent');
        },
        error => {
          this._logger.error('Erro a enviar voucher para cliente.', error);
        }
      );
  }
}
