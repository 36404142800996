import { Directive, ElementRef, HostListener, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createTextMaskInputElement } from 'text-mask-core';

@Directive({
  selector: '[textMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextMaskDirective),
      multi: true
    }
  ]
})
export class TextMaskDirective implements OnInit, ControlValueAccessor {
  @Input('textMask') textMaskConfig: any = {
    mask: [],
    guide: true,
    placeholderChar: '_',
    pipe: undefined,
    keepCharPositions: false
  };

  private textMaskInputElement: any;
  private inputElement: HTMLInputElement;
  private _onTouched = () => {};
  private _onChange = (_: any) => {};

  constructor(private elementRef: ElementRef) {
    this.inputElement = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.textMaskInputElement = createTextMaskInputElement({
      inputElement: this.inputElement,
      ...this.textMaskConfig
    });
    this.textMaskInputElement.update(this.inputElement.value);
  }

  writeValue(value: any): void {
    if (this.textMaskInputElement && value) {
      this.textMaskInputElement.update(value);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  @Input() set textMask(value: any) {
    this.textMaskConfig = value;
    if (this.textMaskInputElement) {
      this.textMaskInputElement = createTextMaskInputElement({
        inputElement: this.inputElement,
        ...this.textMaskConfig
      });
      this.textMaskInputElement.update(this.inputElement.value);
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: any) {
    if (this.textMaskInputElement) {
      this.textMaskInputElement.update();
      this._onChange(this.inputElement.value);
    }
  }

  @HostListener('blur')
  onBlur() {
    this._onTouched();
  }
}
