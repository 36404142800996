import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/angular-ivy';
// import { version } from '../package.json';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// const version = require('../package.json').version;

Sentry.init({
  dsn: 'https://89f0eeea3ec843949d3ff1d696ef2d2d@o1114358.ingest.sentry.io/6145876',
  release: '1.0.9',
  environment: environment.env,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://dev-private-api.dreampay.com.br',
        'https://private-api.dreampay.com.br',
        'https://payment.dreampay.com.br',
        'https://dev-payment.dreampay.com.br',
        /^\//
      ],
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
