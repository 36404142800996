import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { EstablishmentsService, PaymentLinksService } from 'api-client';
import { LogService } from 'src/app/services/log.service';
import { StoreService } from 'src/app/services/store.service';
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { StepperService } from 'src/app/services/stepper.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  @ViewChild('stepper') stepper: MatStepper;

  // Getters to access store state for template binding
  get paymentLink() {
    return this._storeService.getPaymentLink();
  }

  get establishment() {
    return this._storeService.getEstablishment();
  }

  get showPixStep() {
    return this._storeService.getShowPixStep();
  }

  get finishStepMessage() {
    return this._storeService.getFinishStepMessage();
  }

  constructor(
    private _paymentLinksService: PaymentLinksService,
    private _establishmentsService: EstablishmentsService,
    private _route: ActivatedRoute,
    private _logger: LogService,
    private _router: Router,
    private _storeService: StoreService,
    private _stepperService: StepperService
  ) {}

  ngOnInit() {
    const linkId = this._route.snapshot.paramMap.get('id');
    // Store the linkId in the global store
    this._storeService.setLinkId(linkId);
    this._logger.tag('linkId', linkId);

    // Subscribe to stepper navigation events
    this.subscription.add(
      this._stepperService.stepperNavigation$.subscribe(action => {
        if (!this.stepper) return;

        if (action === 'next') {
          this.stepper.next();
        } else if (action === 'final') {
          this.stepper.selectedIndex = this.stepper.steps.length - 1;
        } else if (action.startsWith('goto:')) {
          const index = parseInt(action.split(':')[1], 10);
          if (!isNaN(index) && index >= 0 && index < this.stepper.steps.length) {
            this.stepper.selectedIndex = index;
          }
        }
      })
    );

    this._paymentLinksService
      .apiPaymentLinksIdGet(linkId)
      .pipe(take(1))
      .subscribe({
        next: paymentLink => {
          // Store the payment link in the global store
          this._storeService.setPaymentLink(paymentLink);

          this._establishmentsService
            .apiEstablishmentsIdGet(paymentLink.establishmentID)
            .pipe(take(1))
            .subscribe({
              next: establishment => {
                // Store the establishment in the global store
                this._storeService.setEstablishment(establishment);

                // Handle navigation based on payment link type
                if (paymentLink.linkType === 1) {
                  this._router.navigate(['/pay-checkout/' + linkId]);
                }
                if (paymentLink.linkType === 2) {
                  this._router.navigate(['/pay-grerj/' + linkId]);
                }
                if (paymentLink.paid && (paymentLink.linkType === 0 || paymentLink.linkType === 2)) {
                  this._router.navigate(['/paid-link']);
                }
                if (paymentLink.expired && (paymentLink.linkType === 0 || paymentLink.linkType === 2)) {
                  this._router.navigate(['/expired-link']);
                }
                if (paymentLink.canceled) {
                  this._router.navigate(['/canceled-link']);
                }
              }
            });
        },
        error: error => {
          this._logger.debug('Invalid link.');
          this._logger.error(error);
          this._router.navigate(['/invalid-link']);
        }
      });
  }

  ngOnDestroy() {
    // Clean up subscriptions to prevent memory leaks
    this.subscription.unsubscribe();
  }
}
