import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

export interface PaymentNotification {
  status: string;
  paymentLinkId: string;
  posId?: string;
  transactionId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  private paymentNotificationSubject = new Subject<PaymentNotification>();
  private connectionId: string;

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.API_BASE_PATH}/returnedhub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Warning)
      .build();
  }

  public async startConnection(): Promise<void> {
    try {
      await this.hubConnection.start();
      this.connectionId = this.hubConnection.connectionId;
      this.registerSignalRListeners();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async stopConnection(): Promise<void> {
    try {
      if (this.hubConnection) {
        await this.hubConnection.stop();
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  private registerSignalRListeners(): void {
    this.hubConnection.on('ReceivePayment', (notification: PaymentNotification) => {
      this.paymentNotificationSubject.next(notification);
    });

    this.hubConnection.on('ReceivePaymentNotification', (notification: PaymentNotification) => {
      this.paymentNotificationSubject.next(notification);
    });

    this.hubConnection.on('PaymentStatusChanged', (notification: PaymentNotification) => {
      this.paymentNotificationSubject.next(notification);
    });

    this.hubConnection.on('PaymentNotification', (notification: PaymentNotification) => {
      this.paymentNotificationSubject.next(notification);
    });

    // Add handler for PixPaid event
    this.hubConnection.on('PixPaid', (notification: PaymentNotification) => {
      this.paymentNotificationSubject.next(notification);
    });

    // Add handler for pixpaid event (lowercase version)
    this.hubConnection.on('pixpaid', (notification: PaymentNotification) => {
      this.paymentNotificationSubject.next(notification);
    });

    this.hubConnection.onreconnecting(error => {
      // Connection is attempting to reconnect
    });

    this.hubConnection.onreconnected(connectionId => {
      this.connectionId = connectionId;
    });

    this.hubConnection.onclose(error => {
      // Connection closed
    });
  }

  public getPaymentNotifications(): Observable<PaymentNotification> {
    return this.paymentNotificationSubject.asObservable();
  }
}
