import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { delay, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoadingService } from './services/loading.service';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'payment-dreampay';
  loading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    private _translateService: TranslateService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
    });

    // Add languages
    this._translateService.addLangs(['pt', 'en']);

    // Set the default language
    this._translateService.setDefaultLang('pt');

    // Use a language
    this._translateService.use('pt');
  }

  ngOnInit() {
    console.debug('used API:', environment.apiURL);
    Sentry.setTag('api', environment.apiURL);
    this.listenToLoading();
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe(loading => {
        this.loading = loading;
      });
  }
}
